<template>
     <div class="row section">
        <div class="col-lg-5 col-md-7">
            <h3>Heeft u een vraag?</h3>
            <p>
                Via het onderstaande contactformulier kunt u contact opnemen. We doen ons best om uw vraag binnen 24 uur te beantwoorden.
            </p>
            <transition name="fade">
                <div class="errors" v-if="errors.length" key="errors">
                    <ul class="checklist">
                        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                    </ul>
                </div>
                <div class="success" v-if="sent" key="success">
                    <ul class="checklist">
                        <li>Het contactformulier is succesvol verzonden. We proberen z.s.m. contact met u op te nemen.</li>
                    </ul>
                </div>
            </transition>
            <div class="contact--form">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Naam" v-model="name">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="E-mail" v-model="email">
                </div>
                <div class="form-group">
                    <textarea name="" id="" rows="3" class="form-control" placeholder="Bericht" v-model="message"></textarea>
                </div>
            </div>
            <a href="#" class="btn btn-secondary btn-icon" @click.prevent="SubmitForm()"><img src="@/assets/img/icons/envelope.svg" />Verzenden</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors: [],
            name: '',
            email: '',
            message: '',
            sent: false
        }
    },
    methods: {
        SubmitForm() {
            this.sent = false;
            this.errors = [];

            if (!this.name) this.errors.push('Naam is een verplicht veld.');
            if (!this.email) {
                this.errors.push('E-mail is een verplicht veld.');
            } else if (!this.validEmail(this.email)) {
                this.errors.push('Vul a.u.b. een geldig e-mailadres in.');
            }
            if (!this.message) this.errors.push('Bericht is een verplicht veld.');

            if (this.errors.length) {
                return false;
            }

            this.errors = [];

            var vm = this;
            /* eslint-disable */
            $.ajax({
                url: '/scripts/contact.php',
                method: 'POST',
                data: {
                    Name: vm.name,
                    Email: vm.email,
                    Message: vm.message
                },
                success: () => {
                    vm.sent = true;

                    vm.name = '';
                    vm.email = '';
                    vm.message = '';
                }
            });
            /* eslint-enable */
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }
}
</script>
