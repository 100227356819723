<template>
    
    <page page="reserveren" theme="light" type="page">

        <div class="container">
            <div class="row section">
                <div class="col-lg-6">
                    <h3>Direct reserveren</h3>
                    <p>
                        Via het onderstaande formulier kunt u een reservering maken bij onze bed &amp; breakfast.
                    </p>
                    <iframe src="https://www.bedandbreakfast.fiks.online/Booking/Iframe/0be5a7ee-d8b6-45f5-99b1-ef59fdd96ecb" width="100%" frameborder="0" allowtransparency="true" style="min-height: 450px; border-radius: 9px;"></iframe>
                </div>
                <div class="col-lg-5 col-lg-offset-1">
                    <h3>Arrangement bijboeken</h3>
                    <!--<p>
                        Bij een reservering via deze website van <strong>minimaal 3 nachten heeft u recht op een gratis kleine rondvaart</strong> door de Biesbosch.
                    </p>
                    <p>
                        Als u geïnteresseerd bent in een rondvaart dan kunt u via het contactformulier contact met ons opnemen. Vermeld a.u.b. op welke datum u gereserveerd heeft.
                    </p>-->
                    <p>
                        Wel geïnteresseerd maar nog niet bekend met onze arrangementen? Neem een kijkje op onze arrangementen pagina.
                    </p>
                    <!-- <p>
                        Indien u een reservering maakt voor minimaal 3 nachten heeft u recht op een kleine rondvaart door de Biesbosch.
                    </p>
                    <p>
                        Als u geïnteresseerd bent in de grote rondvaart dan kunt u op het betalings scherm deze optie selecteren.
                    </p>
                    <p>
                        Wel geïnteresseerd maar nog niet bekend met onze arrangementen? Neem een kijkje op onze arrangementen pagina.
                    </p>-->
                    <router-link to="/arrangementen" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/users.svg" />Onze arrangementen</router-link>
                </div>
            </div>
        </div>

        <div class="image--block pos-right">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="accomodatie/small/bed.jpg"></background-image>
            </div>
            <div class="container">
                
                <contact-form></contact-form>

            </div>
        </div>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
import ContactForm from '../modules/AppContactForm.vue'
//import Reservation from '../modules/AppReservation.vue'

export default {
    metaInfo: {
        title: 'Reserveren',
        meta: [
            { vmid: 'description', name: 'description', content: 'Wilt u gelijk reserveren? Dat kan via het onderstaande formulier. Heeft u nog vragen dan kunt u het contactformulier invullen.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/reserveren'}
        ]
    },
    data() {
        return {
            
        }
    },
    components: {
        Page,
        ContactForm
        //Reservation
    }
}

var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
var eventer = window[eventMethod];
var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

eventer(messageEvent, function (e) {
    if (e.data.message === "FIKS_BOOKING") {
        window.location = e.data.url;
    }
});
</script>
